<template>
  <div class="menu-wrapper">
    <Header :menus="menus" @update:activeTab="activeTab = $event" />
    <SubHeader msg="" :currentMenu="this.currentMenu" />
    <MenuSection :currentMenu="currentMenu" />
  </div>
</template>

<script>
import adega from "../../utils/dishesPTBR/adega.json";
import bebidas_industrializadas from "../../utils/dishesPTBR/bebidas_industrializadas.json";
import bebidas_tarpon from "../../utils/dishesPTBR/bebidas_tarpon.json";
import coworking_lanches from "../../utils/dishesPTBR/coworking_lanches.json";
// import destilados from "../../utils/dishesPTBR/destilados.json";
import drinks from "../../utils/dishesPTBR/drinks.json";
import entradas from "../../utils/dishesPTBR/entradas.json";
// import espumantes_xaropes from "../../utils/dishesPTBR/espumantes_xaropes.json";
import frigobar from "../../utils/dishesPTBR/frigobar.json";
import menu_experience from "../../utils/dishesPTBR/menu_experience.json";
import menu_kids from "../../utils/dishesPTBR/menu_kids.json";
import pratos_principais from "../../utils/dishesPTBR/pratos_principais.json";
import room_service from "../../utils/dishesPTBR/room_service.json";
import sobremesas from "../../utils/dishesPTBR/sobremesas.json";
import uca_rooftop from "../../utils/dishesPTBR/uca_rooftop.json";
import saladas from "../../utils/dishesPTBR/saladas.json";
import destilados from "../../utils/dishesPTBR/destilados.json";
import massas from "../../utils/dishesPTBR/massas.json";
import Personalizados from "../../utils/dishesPTBR/Personalizados.json";
import Boutique from "../../utils/dishesPTBR/Boutique.json";
import pizzas from "../../utils/dishesPTBR/pizzas.json";
import menu_executivo from "../../utils/dishesPTBR/menu_executivo.json";
// import menu_dia_das_mães from "../../utils/dishesPTBR/menu_dia_das_mães.json";
// import menu_dia_dos_pais from "../../utils/dishesPTBR/menu_dia_dos_pais.json";

import happy_hour from "../../utils/dishesPTBR/happy_hour.json";
import menu_pet_friendly from "../../utils/dishesPTBR/menu_pet_friendly.json";
import drinks_tarpon from "../../utils/dishesPTBR/drinks_tarpon.json";

// import dishes from "../../utils/dishesPTBR/dishes-PTBR.json";

import MenuSection from "../components/MenuSection.vue";
import SubHeader from "../components/SubHeader.vue";
import Header from "../components/Header.vue";

export default {
  components: {
    MenuSection,
    SubHeader,
    // eslint-disable-next-line vue/no-reserved-component-names
    Header,
  },
  name: "MenuWrapper",
  props: {},
  data() {
    return {
      // dishes: dishes,
      activeTab: 0,
      menus: [
        {
          section: "Mero Restaurante",
          isDropdownDisabled: false,
          subMenu: [
            entradas,
            saladas,
            massas,
            pratos_principais,
            menu_kids,
            menu_executivo,
            pizzas,
            sobremesas,
            bebidas_industrializadas,
            drinks,
            destilados,
            adega,
          ],
        },
        {
          section: "Coworking",
          isDropdownDisabled: true,
          subMenu: [
            coworking_lanches,
            bebidas_industrializadas,
            drinks,
            destilados,
          ],
        },
        {
          section: "Frigobar",
          isDropdownDisabled: true,
          subMenu: [frigobar, room_service, Personalizados, Boutique],
        },
        {
          section: "Uça Rooftop",
          isDropdownDisabled: false,
          subMenu: [
            uca_rooftop,
            pizzas,
            sobremesas,
            bebidas_industrializadas,
            drinks,
            adega,
            destilados,
          ],
        },
        {
          section: "Room Service",
          isDropdownDisabled: false,
          subMenu: [
            room_service,
            pizzas,
            frigobar,
            Personalizados,
            menu_pet_friendly,
          ],
        },
        {
          section: "Menu Experience",
          isDropdownDisabled: true,
          subMenu: [menu_experience],
        },
        {
          section: "Panaque",
          isDropdownDisabled: true,
          subMenu: [
            uca_rooftop,
            pizzas,
            sobremesas,
            bebidas_industrializadas,
            drinks,
            adega,
            destilados,
          ],
        },
        {
          section: "Tarpon Praia",
          isDropdownDisabled: this.isDropdownDisabledByhour(
            false,
            [1, 2, 3, 4, 5, 6, 0],
            "08:00",
            "18:00"
          ),
          subMenu: [drinks_tarpon, bebidas_tarpon],
        },
        {
          section: "Eventos",
          isDropdownDisabled: this.isDropdownDisabledByhour(
            true,
            [5],
            "16:00",
            "16:01"
          ),
          subMenu: [
            bebidas_industrializadas,
            pizzas,
            drinks,
            adega,
            destilados,
          ],
        },
        {
          section: "Happy Hour",
          isDropdownDisabled: this.isDropdownDisabledByhour(
            true,
            [5],
            "16:00",
            "20:00"
          ),
          subMenu: [happy_hour],
        },
      ],
      currentMenu: null,
      isScrollable: true,
      maxHeight: 400,
    };
  },
  watch: {
    activeTab(value) {
      this.currentMenu = this.menus[value];
    },
  },
  computed: {},
  created() {
    this.currentMenu = this.menus[this.activeTab];
  },
  methods: {
    /*
     * Check if dropdown is disabled by hour
     * @param {Boolean} isDisabled - If isDisabled is true, dropdown is disabled
     * @param {Array<Number>} selectedWeekDate - Array with the days of the week that the dropdown is active
     * @param {String} startHour - Start hour Format "HH:MM"
     * @param {String} endHour - End hour Format "HH:MM"
     * Se isDisabled for false, o dropdown está desativado
     */
    isDropdownDisabledByhour(
      isDisabled = false,
      selectedWeekDate = [5],
      startHour = "17:00",
      endHour = "20:00"
    ) {
      // 1 - Segunda feira
      // 2 - Terça feira
      // 3 - Quarta feira
      // 4 - Quinta feira
      // 5 - Sexta feira
      // 6 - Sábado
      // 0 - Domingo
      if (isDisabled) {
        return true;
      }

      const date = new Date();
      const weekDay = date.getDay();
      const hour = parseInt(date.getHours());
      const minute = parseInt(date.getMinutes());
      const includesWeekDay = selectedWeekDate.includes(weekDay);

      if (!includesWeekDay) {
        return true;
      }

      const [startHourHour, startHourMinute] = startHour.split(":");
      const [endHourHour, endHourMinute] = endHour.split(":");

      if (hour >= parseInt(startHourHour) && hour <= parseInt(endHourHour)) {
        if (
          hour === parseInt(startHourHour) &&
          minute < parseInt(startHourMinute)
        ) {
          return true;
        }
        if (
          hour === parseInt(endHourHour) &&
          minute > parseInt(endHourMinute)
        ) {
          return true;
        }
        return false;
      }

      return true;
    },
  },
};
</script>

<style scoped>
.menu-section {
  width: 100%;
  height: 100%;
  padding: 6px;
}

.dropdown-style {
  background-color: #554b45;
  color: white;
  border: 0;
  min-width: 250px;
  text-transform: uppercase;
}

a.dropdown-item.is-active,
.dropdown .dropdown-menu .has-link a.is-active,
button.dropdown-item.is-active {
  background-color: #554b45;
  color: #fff;
}
</style>
